
<mat-grid-list class="web-content" cols="2" rowHeight="4:3">
  <mat-grid-tile>
    <img
      mat-card-image
      src="../../assets/images/animals/FcqWI6TXEAEa2pm.jpeg"
      alt="Photo of grass land"
    />
  </mat-grid-tile>
  <mat-grid-tile>
    <img
      mat-card-image
      src="../../assets/images/animals/Fu6BRmpWYAEFanj.jpeg"
      alt="Photo of a cow"
    />
  </mat-grid-tile>
</mat-grid-list>
<mat-grid-list class="mobile-content" cols="1" rowHeight="2:1">
  <mat-grid-tile>
    <img
      mat-card-image
      src="../../assets/images/animals/Goat1.jpg"
      alt="Photo of a goat"
    />
  </mat-grid-tile>
</mat-grid-list>



<mat-card class="mobile-content conten-card">
  <mat-card-header>
    <mat-card-title>Eat Fresh. Stay Healthy.</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>{{ longText }}</p>
    <mat-divider></mat-divider>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button>LIKE</button>
    <button mat-button>SHARE</button>
  </mat-card-actions>
</mat-card>

<mat-card class="mobile-content conten-card">
  <img mat-card-image src="../../assets/images/hay/AlfalfaGrass1.jpeg" alt="Alfalfa">
  <mat-card-content>
    <p>
      Alfalfa Hay.
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button>Buy</button>
    <button mat-button>SHARE</button>
  </mat-card-actions>
</mat-card>

<mat-card class="mobile-content conten-card">
  <img mat-card-image src="../../assets/images/hay/SudanGrass1.jpeg" alt="Sudan Grass">
  <mat-card-content>
    <p>
      Sudan Grass Hay.
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button>Buy</button>
    <button mat-button>SHARE</button>
  </mat-card-actions>
</mat-card> 

<!-- <mat-grid-list class="mobile-content" cols="1" rowHeight="3:3">
  <mat-grid-tile>
    
    
  </mat-grid-tile>
  <mat-grid-tile>
       
  </mat-grid-tile>
</mat-grid-list> -->


<!-- <mat-grid-list class="mobile-content" cols="1" rowHeight="3:3">
  <mat-grid-tile>
    <mat-card class="conten-card">
      <img mat-card-image src="../../assets/images/hay/AlfalfaGrass1.jpeg" alt="Alfalfa">
      <mat-card-content>
        <p>
          Alfalfa Hay.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>Buy</button>
        <button mat-button>SHARE</button>
      </mat-card-actions>
    </mat-card>
    
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="conten-card">
      <img mat-card-image src="../../assets/images/hay/SudanGrass1.jpeg" alt="Sudan Grass">
      <mat-card-content>
        <p>
          Sudan Grass Hay.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>Buy</button>
        <button mat-button>SHARE</button>
      </mat-card-actions>
    </mat-card>    
  </mat-grid-tile>
</mat-grid-list> -->

<mat-grid-list class="web-content" cols="3" rowHeight="4:4">
  <mat-grid-tile>
    <mat-card class="conten-card">
      <!-- <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>Shiba Inu</mat-card-title>
        <mat-card-subtitle>Dog Breed</mat-card-subtitle>
      </mat-card-header> -->
      <img mat-card-image src="../../assets/images/hay/AlfalfaGrass1.jpeg" alt="Alfalfa">
      <mat-card-content>
        <p>
          Alfalfa Hay.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>Buy</button>
        <button mat-button>SHARE</button>
      </mat-card-actions>
    </mat-card>
    
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="conten-card">
      <!-- <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>Shiba Inu</mat-card-title>
        <mat-card-subtitle>Dog Breed</mat-card-subtitle>
      </mat-card-header> -->
      <img mat-card-image src="../../assets/images/hay/SudanGrass1.jpeg" alt="Sudan Grass">
      <mat-card-content>
        <p>
          Sudan Grass Hay.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>Buy</button>
        <button mat-button>SHARE</button>
      </mat-card-actions>
    </mat-card>    
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="conten-card">
      <!-- <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>Shiba Inu</mat-card-title>
        <mat-card-subtitle>Dog Breed</mat-card-subtitle>
      </mat-card-header> -->
      <img mat-card-image src="../../assets/images/hay/SudanGrass1.jpeg" alt="Sudan Grass">
      <mat-card-content>
        <p>
          Sudan Grass Hay.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>Buy</button>
        <button mat-button>SHARE</button>
      </mat-card-actions>
    </mat-card>    
  </mat-grid-tile>
</mat-grid-list>
<mat-grid-list class="mobile-content" cols="1" rowHeight="2:1">
  <mat-grid-tile>
    <img
      mat-card-image
      src="../../assets/images/animals/FtljV3qXwAAD8p8.jpeg"
      alt="Photo of a goat"
    />
  </mat-grid-tile>
</mat-grid-list>


<div class="content" role="main">
  <mat-card class="conten-card">
    <mat-card-header>
      <!-- <mat-card-subtitle>Drink Fresh. Stay Healthy.</mat-card-subtitle> -->
      <mat-card-title>Eat Fresh. Stay Healthy.</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>{{ longText }}</p>
      <mat-divider></mat-divider>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button>LIKE</button>
      <button mat-button>SHARE</button>
    </mat-card-actions>
    <mat-card-footer>
      <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
    </mat-card-footer>
  </mat-card>

  <!-- Highlight Card -->
  
  <!-- <div class="card highlight-card card-small">
    <H1> Eat Fresh. Stay Healthy. </H1>
  </div> -->


  <mat-slide-toggle>Toggle me!</mat-slide-toggle>

  <h2>Resources</h2>
  <p>Here are some links to help you get started:</p>

  <div class="card-container">
    <a
      class="card"
      target="_blank"
      rel="noopener"
      href="https://angular.io/tutorial"
    >
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z"
        />
      </svg>

      <span>Learn Angular</span>

      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </a>

    <a
      class="card"
      target="_blank"
      rel="noopener"
      href="https://angular.io/cli"
    >
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"
        />
      </svg>

      <span>CLI Documentation</span>

      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </a>

    <a
      class="card"
      target="_blank"
      rel="noopener"
      href="https://blog.angular.io/"
    >
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          d="M13.5.67s.74 2.65.74 4.8c0 2.06-1.35 3.73-3.41 3.73-2.07 0-3.63-1.67-3.63-3.73l.03-.36C5.21 7.51 4 10.62 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8C20 8.61 17.41 3.8 13.5.67zM11.71 19c-1.78 0-3.22-1.4-3.22-3.14 0-1.62 1.05-2.76 2.81-3.12 1.77-.36 3.6-1.21 4.62-2.58.39 1.29.59 2.65.59 4.04 0 2.65-2.15 4.8-4.8 4.8z"
        />
      </svg>

      <span>Angular Blog</span>

      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </a>
  </div>

  <!-- Next Steps -->
  <h2>Next Steps</h2>
  <p>What do you want to do next with your app?</p>

  <input type="hidden" #selection />

  <div class="card-container">
    <div
      class="card card-small"
      (click)="selection.value = 'component'"
      tabindex="0"
    >
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
      </svg>

      <span>New Component</span>
    </div>

    <div
      class="card card-small"
      (click)="selection.value = 'material'"
      tabindex="0"
    >
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
      </svg>

      <span>Angular Material</span>
    </div>

    <div class="card card-small" (click)="selection.value = 'pwa'" tabindex="0">
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
      </svg>

      <span>Add PWA Support</span>
    </div>

    <div
      class="card card-small"
      (click)="selection.value = 'dependency'"
      tabindex="0"
    >
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
      </svg>

      <span>Add Dependency</span>
    </div>

    <div
      class="card card-small"
      (click)="selection.value = 'test'"
      tabindex="0"
    >
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
      </svg>

      <span>Run and Watch Tests</span>
    </div>

    <div
      class="card card-small"
      (click)="selection.value = 'build'"
      tabindex="0"
    >
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
      </svg>

      <span>Build for Production</span>
    </div>
  </div>
</div>
