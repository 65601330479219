import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  longText: string = '';
  constructor() {}

  ngOnInit(): void {
    this.longText = `It's not that we don't have organic products. People doesn't have a platform to buy those and 
    farmers don't have a platform to sell those. We connect them and guarantee the products sold here.`;
  }
}
